import './style.scss'
import logo from '../../../../assets/homepage/lowerFooterSection/footer_logo.png'
import facebook from '../../../../assets/homepage/lowerFooterSection/facebook.png'
import tweeter from '../../../../assets/homepage/lowerFooterSection/tweet.png'
import insta from '../../../../assets/homepage/lowerFooterSection/insta.png'
import iso from '../../../../assets/oth/iso-card.png'

export default function LowerFooter() {

    return (
        <section className='lowerFooter-main'>
            <div className='footer-div'>
                <div className='footer-logo'>
                    <img src={logo} className="flogo"/>
                    <img src={iso} className="fios"/>
                </div>
                <div className='footer-mobile-divider'></div>
                <div className='footer-right'>
                    <h1>Connect with Us</h1>
                    <div className='footer-socialMedia'>
                        <img src={facebook}/>
                        <img src={insta}/>
                        <img src={tweeter}/>
                    </div>
                </div>
            </div>
        </section>
    )

}