import { Dispatch } from "redux";
import {
  CLEAR_REPORT_LIST,
  CLEAR_APP_SELECTION,
  CLEAR_USER_DETAILS,
  SELECT_APP,
  SET_USER_DETAILS,
  SET_REPORT_LIST,
  SET_APPS_COUNT,
} from "../types";
import { UserReducerStore } from "../reducers/userReducer";
import { AppListItem, ReportListItem } from "../../interface/api";

export const setUserDetails =
  (payload: UserReducerStore): any =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: SET_USER_DETAILS,
      payload,
    });
  };

export const clearUserDetails = (): any => async (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_USER_DETAILS,
  });
};

export const setSelectedApp: any =
  (payload: AppListItem) => (dispatch: Dispatch) => {
    dispatch({
      type: SELECT_APP,
      payload,
    });
  };

export const setAppsCount: any =
  (payload: number) => (dispatch: Dispatch) => {
    dispatch({
      type: SET_APPS_COUNT,
      payload,
    });
  };

export const setReports: any =
  (payload: ReportListItem[]) => (dispatch: Dispatch) => {
    dispatch({
      type: SET_REPORT_LIST,
      payload,
    });
  };

export const clearSelectedApp: any = () => async (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_APP_SELECTION,
  });
  dispatch({
    type: CLEAR_REPORT_LIST,
  });
};
