import { getFormattedDate, openInNewTab } from "../helpers/helper";
import { GetChartDetailsResponse } from "../interface/analytics";
import {
  BaseResponse,
  GetAppDetailsResponse,
  GetAppsResponse,
  LoginResponse,
} from "../interface/api";
import { GetReportDataResponse } from "../interface/reports";
import store from "../store/store";

import {
  authorisedApiCall,
  defaultCatch,
  fetchHandler,
  responseHelper,
  unauthorisedApiCall,
} from "./utils";

class APIS {
  private showLoader = (loaderTitle?: string | undefined) => {};
  private hideLoader = (loaderTitle?: string | undefined) => {};
  private static instance: APIS | null = null;
  public instanceId = "TEST";

  constructor(instanceId: string) {
    this.instanceId = instanceId;
  }

  static getInstance() {
    return APIS.instance || (APIS.instance = new APIS("TEST NEW 1"));
  }

  // getRole() {
  //   return store.getState().userReducer.role.toLowerCase() || "user";
  // }

  initialize(
    showLoader: (loaderTitle?: string | undefined) => void,
    hideLoader: () => void
  ) {
    this.showLoader = showLoader;
    this.hideLoader = hideLoader;
  }

  login(email: string, password: string): Promise<LoginResponse> {
    this.showLoader("Login...");
    return unauthorisedApiCall("/users/login", { email, password })
      .then(fetchHandler)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(this.hideLoader);
  }

  logout(): Promise<BaseResponse> {
    this.showLoader("Logout...");
    return authorisedApiCall("/users/logout", {}, "GET")
      .then(fetchHandler)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(this.hideLoader);
  }

  getApps(): Promise<GetAppsResponse> {
    // this.showLoader("Fetching App(s)...");
    return authorisedApiCall("/apps", {}, "GET")
      .then(fetchHandler)
      .then(responseHelper)
      .catch(defaultCatch);
    // .finally(this.hideLoader);
  }

  getAppDetails(appKey: string): Promise<GetAppDetailsResponse> {
    this.showLoader("Fetching App Details...");
    return authorisedApiCall(`/apps/${appKey}`, {}, "GET")
      .then(fetchHandler)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(this.hideLoader);
  }

  getChartDetails<T>(
    chartKey: string,
    filters: any = {}
  ): Promise<GetChartDetailsResponse<T>> {
    this.showLoader("Fetching Chart Details...");
    return authorisedApiCall(`/charts/${chartKey}`, filters, "GET")
      .then(fetchHandler)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(this.hideLoader);
  }

  getReportData(
    reportKey: string,
    filters: any = {}
  ): Promise<GetReportDataResponse> {
    this.showLoader("Fetching Chart Details...");
    return authorisedApiCall(`/reports/view/${reportKey}`, filters, "GET")
      .then(fetchHandler)
      .then(responseHelper)
      .catch(defaultCatch)
      .finally(this.hideLoader);
  }
  async downloadReport(reportKey: string, filters: any = {}) {
    const { accessToken: token } = await store.getState().authReducer;
    openInNewTab(
      `${
        process.env.REACT_APP_API_BASE_URL
      }/reports/download/${reportKey}?token=${token}&${new URLSearchParams(
        filters
      ).toString()}`
    );
  }
}
const API = APIS.getInstance();

export default API;
