import './style.scss'
import {Features} from "../../components/featuresComponent/Features";

export default function FeaturesSection() {


    return (
        <div className='feature-main'>
            <div className='features-heading'>
            <h1>We make it scalable like a dream</h1>
            </div>
            <div className='features-section'>
                <Features img="sourceTracking" heading={"Total Visit"}
                          subHeading={"Be in the know of the precise number of visits."}/>
                <Features img="time" heading={"Unique Visitor"}
                          subHeading={"First time visitor details for improving engagement"}/>
                <Features img="totalVisit" heading={"User Registration"}
                          subHeading={"The conversion rate data The most validating among all."}/>
                <Features img="traffic" heading={"Source Tracking"}
                          subHeading={"The source tracking gives proper understanding of ads spent."}/>
                <Features img="uniqueVisitors" heading={"Time"}
                          subHeading={"In depth statistics about time spent and  most active timeline."}/>
                <Features img="userRegistration" heading={"Traffic"}
                          subHeading={"Very helpful in geographic targeting of user engagement."}/>
            </div>
        </div>
    )
}