import './style.scss'
import {LoginForm} from "../../components/loginBox/LoginForm";
import main_logo from "../../../../assets/homepage/heroSection/logo.png";
import LottieOne from "../../../../assets/lottie/LottieOne";
import secure_login from "../../../../assets/homepage/heroSection/secure_login.svg"
export default function HeroPage() {

    return (

        <section className='hero_section'>
            <div className='hero_div'>
                <div className="hero_logo">
                    <img src={main_logo}/>
                </div>
                <div className="hero_main">
                    <div className="hero_heading">
                        <h1><span>Analyse </span> Implement Capitalize</h1>
                        <p>Comprehensive Insights, Effortlessly Delivered</p>
                    </div>
                    <div className="hero_img">
                        <LottieOne/>
                    </div>
                </div>
                <div className='login'>
                    <LoginForm/>
                </div>
                <div className='secure_login-wrapper'>
                    <img src={secure_login} alt="secure_login"/>
                    <p className="secure_login" >
                        Login  to access the secure analytics of your campaign
                    </p>
                </div>

            </div>
        </section>

    )
}