import './style.scss'
import {HorizontalCard} from "../../components/securityFeaturesComponents/horizontalCard/horizontalCard";
import {VerticalCard} from "../../components/securityFeaturesComponents/verticalCard/verticalCard";
import desktop from "../../../../assets/oth/device.gif";
import chart from "../../../../assets/oth/charrt.gif";
import analise_impliment from "../../../../assets/oth/coin.gif";
import shield from "../../../../assets/oth/security.gif";

export default function SecurityFeaturesSection() {

    return (
        <section className='securityFeature_section'>
            <HorizontalCard img={chart} heading="Feature Rich minute details with flair"
                            subHeading="In depth graphical representation, analytical pie charts and histograms"
                            style={{backgroundColor: '#254265', color: "white"}}/>
            <div className='small-cards'>
                <VerticalCard img={desktop} heading="Platform friendly"
                              subHeading="Be it desktop or mobile, in-app or web browser- we got you covered, always!"
                              style={{backgroundColor: '#C9E4E5'}}/>
                <VerticalCard img={analise_impliment} heading="Analyse Implement & Capture the market"
                              style={{backgroundColor: '#EDCBBF'}} subHeading=''/>
            </div>
            <HorizontalCard img={shield} heading="Encryption"
                            subHeading="We take security seriously. All our data is end to end, 256 bit encrypted for complete peace of mind."
                            style={{backgroundColor: '#C9E2F3'}}/>
        </section>

    )
}