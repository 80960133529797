import { useParams } from "react-router-dom";
import "./Report.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import API from "../../api";
import useApi from "../../hooks/useApi";
import {
  CustomProvider,
  DateRangePicker,
  Input,
  InputGroup,
  InputPicker,
  Pagination,
  Table,
} from "rsuite";
import useUserInfo from "../../hooks/useUserInfo";
import { Button, Dialog, Tooltip } from "@mui/material";
import { ItemDataType } from "rsuite/esm/@types/common";
import { Download } from "@mui/icons-material";
import { debounce, getFormattedDate } from "../../helpers/helper";
import { FilterListItem } from "../../interface/reports";
import PageError from "../../components/PageError";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
const { allowedRange } = DateRangePicker;

const { Column, HeaderCell, Cell } = Table;

type ReportPageProps = {
  reportId: string;
};

const ReportPage = ({ reportId }: ReportPageProps) => {
  const isDarkMode: boolean = useSelector(
    (state: RootState) => state.appReducer.isDarkMode
  );
  const [filterValues, setFilterValues] = useState<
    Record<string, string | Date>
  >({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const isMounted = useRef(false);

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedPreviewImage, setSelectedPreviewImage] = useState("");

  const apiCall = useCallback(() => {
    const payload: any = { ...filterValues, pageNo: page };
    if (payload.startDate) {
      payload.startDate = getFormattedDate(payload.startDate as Date);
    }
    if (payload.endDate) {
      payload.endDate = getFormattedDate(payload.endDate as Date);
    }
    return API.getReportData(reportId, payload);
  }, [reportId, filterValues, page]);

  const { reportsList } = useUserInfo();
  const reportName = reportsList.reduce((acc, val) => {
    if (val.key === reportId) {
      return val.title;
    }
    return acc;
  }, "");

  const { response, isError, isLoading, fetchData } = useApi(apiCall);

  useEffect(() => {
    // ignore on mount, as api is already called
    if (isMounted.current && reportId) {
      fetchData();
    }
    isMounted.current = true;
  }, [reportId, filterValues, page]);

  useEffect(() => {
    if (response) {
      setTotalPages(response.data.pageCount);
      setTotalRows(response.data.totalRows);
    }
  }, [response]);

  const handleDownload = () => {
    if (reportId) {
      const payload: any = { ...filterValues };
      if (payload.startDate) {
        payload.startDate = getFormattedDate(payload.startDate as Date);
      }
      if (payload.endDate) {
        payload.endDate = getFormattedDate(payload.endDate as Date);
      }
      API.downloadReport(reportId, payload);
    }
  };

  const handleFilterChange = useCallback((param: string, value: string) => {
    setFilterValues((prevState) => ({
      ...prevState,
      [param]: value,
    }));
  }, []);

  const debouncedFilterChange = debounce((val: string, id: string) => {
    handleFilterChange(id, val);
  }, 1000);

  const renderFilter = (filter: FilterListItem) => {
    if (filter.type === "NUMBER" || filter.type === "STRING") {
      return (
        <InputGroup style={{ width: "200px" }}>
          {filter.prefix ? (
            <InputGroup.Addon>{filter.prefix}</InputGroup.Addon>
          ) : (
            <></>
          )}
          <Input
            type={filter.type === "NUMBER" ? "tel" : "text"}
            placeholder={filter.placeholder}
            minLength={filter.minLength}
            maxLength={filter.maxLength}
            defaultValue={(filterValues[filter.param] as string) || ""}
            // value={(filterValues[filter.param] as string) || ""}
            onChange={(val) => {
              debouncedFilterChange(val, filter.param);
            }}
          />
        </InputGroup>
      );
    }
    if (filter.type === "DROPDOWN") {
      return (
        <InputPicker
          data={filter.options.reduce((p: ItemDataType[], c) => {
            p.push({
              value: c.value,
              label: c.name,
            });
            return p;
          }, [])}
          value={filterValues[filter.param] || ""}
          onChange={(val) => {
            handleFilterChange(filter.param, val);
          }}
        />
      );
    }
    if (filter.type === "DATE-RANGE") {
      const [param1, param2] = filter.param.split(",");
      return (
        <DateRangePicker
          placeholder="Select Date Range"
          format="dd/MM/yyyy"
          placement="bottomEnd"
          disabledDate={
            allowedRange && allowedRange(filter.endDate, new Date())
          }
          value={
            filterValues[param1] && filterValues[param2]
              ? [filterValues[param1] as Date, filterValues[param2] as Date]
              : null
          }
          onChange={(values) => {
            setFilterValues((prevState) => ({
              ...prevState,
              [param1]: values ? values[0] : "",
              [param2]: values ? values[1] : "",
            }));
          }}
        />
      );
    }
    return <></>;
  };

  if (isError) {
    return <PageError onRetry={fetchData} />;
  }

  return (
    <div className="reports-page">
      <div className="reports-table-wrapper">
        <div className="header">
          <p className="title">{reportName}</p>
          <div className="filters">
            {response?.data.filters && response?.data.filters.map(renderFilter)}
            <Button onClick={handleDownload}>
              <Download />
              Download
            </Button>
          </div>
        </div>
        <div className="table-wrapper">
          <CustomProvider theme={isDarkMode ? "dark" : "light"}>
            <Table<any, any>
              fillHeight
              data={response?.data.data}
              loading={isLoading}
            >
              {response?.data.dataHeader.map((col) => (
                <Column flexGrow={1} align="center" key={col.title}>
                  <HeaderCell>{col.title}</HeaderCell>
                  <Cell
                    dataKey={col.dataIndex.toString()}
                    fullText
                    renderCell={(value) => {
                      if (col.type === "image") {
                        const url = `${col.baseUrl}${value}`;
                        if (value !== null) {
                          return (
                            <span>
                              <img
                                onClick={() => {
                                  setSelectedPreviewImage(url);
                                  setShowPreviewModal(true);
                                }}
                                className="preview-image"
                                src={url}
                                alt={value}
                              />
                            </span>
                          );
                        } else {
                          return null;
                        }
                      }
                      if (value && value.length > 25) {
                        return (
                          <Tooltip title={value}>
                            <span>
                              {value && value.length > 25
                                ? value.slice(0, 22) + "..."
                                : value}
                            </span>
                          </Tooltip>
                        );
                      }
                      return value;
                    }}
                  />
                </Column>
              ))}
            </Table>
          </CustomProvider>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              // ellipsis
              // boundaryLinks
              maxButtons={5}
              size="xs"
              layout={["total", "-", "pager", "|", "skip"]}
              total={totalRows}
              pages={totalPages}
              activePage={page}
              onChangePage={setPage}
            />
          </div>
        </div>
      </div>

      <Dialog
        open={showPreviewModal}
        onClose={() => {
          setShowPreviewModal(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <img src={selectedPreviewImage} alt="Preview" className="preview-big" />
      </Dialog>
    </div>
  );
};

export default ReportPage;
