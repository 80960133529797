import { Dispatch } from "redux";
import { CLEAR_ACCESS_DETAILS, SET_ACCESS_TOKEN } from "../types";

export const setAccessToken: any =
  (payload = "") =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: SET_ACCESS_TOKEN,
      payload,
    });
  };
export const clearAccessDetails: any = () => async (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_ACCESS_DETAILS,
  });
};
