import { Error } from "@mui/icons-material";
import { Button } from "@mui/material";

type PageErrorProps = {
  title?: string;
  description?: string;
  onRetry?: () => void;
};

const PageError = ({
  title = "Error fetching data",
  description = "Please try again later",
  onRetry = () => {},
}: PageErrorProps) => {
  return (
    <div className="fill flex-center grow mb-20">
      <div>
        <Error color="error" fontSize="large" />
        <p className="title">Error fetching data</p>
        <p className="subtitle">Please try again later</p>
        <Button variant="contained" className="retry" onClick={onRetry}>
          Retry
        </Button>
      </div>
    </div>
  );
};

export default PageError;
