import "rsuite/dist/rsuite-no-reset.min.css";
import "./style.scss";
import DropdownMenu from "../dropdown/Dropdown";
import {CustomProvider, DateRangePicker, Input, InputGroup, InputPicker} from "rsuite";
import React, { useCallback, useEffect, useState } from "react";
import { debounce, getFormattedDate } from "../../../../helpers/helper";
import useUserInfo from "../../../../hooks/useUserInfo";
import { FilterListItem } from "../../../../interface/reports";
import { ItemDataType } from "rsuite/esm/@types/common";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
const { allowedRange } = DateRangePicker;

interface DashboardHeading {
  heading: string;
  filtersList?: FilterListItem[];
  onFilterChange?: (filterPayload: any) => void;
}

const Header: React.FC<DashboardHeading> = ({
  heading,
  filtersList,
  onFilterChange,
}) => {

  const isDarkMode: boolean = useSelector(
      (state: RootState) => state.appReducer.isDarkMode
  );

  const { selectedApp } = useUserInfo();
  const [filterValues, setFilterValues] = useState<
    Record<string, string | Date>
  >({});

  useEffect(() => {
    filtersList?.forEach((filter) => {
      if (filter.type === "DATE-RANGE") {
        const [param1, param2] = filter.param.split(",");
        setFilterValues((prevState) => ({
          ...prevState,
          [param1]: filter.endDate ? new Date(filter.endDate) : "",
          [param2]: new Date(),
        }));
      }
    });
  }, [filtersList]);

  useEffect(() => {
    if (onFilterChange) {
      const payload: any = { ...filterValues };
      if (payload.startDate) {
        payload.startDate = getFormattedDate(payload.startDate as Date);
      }
      if (payload.endDate) {
        payload.endDate = getFormattedDate(payload.endDate as Date);
      }
      onFilterChange(payload);
    }
  }, [filterValues, onFilterChange]);

  const handleFilterChange = useCallback((param: string, value: string) => {
    setFilterValues((prevState) => ({
      ...prevState,
      [param]: value,
    }));
  }, []);

  const debouncedFilterChange = debounce((val: string, id: string) => {
    handleFilterChange(id, val);
  }, 1000);

  const renderFilter = (filter: FilterListItem) => {
    if (filter.type === "NUMBER" || filter.type === "STRING") {
      return (
        <InputGroup style={{ width: "200px" }}>
          {filter.prefix ? (
            <InputGroup.Addon>{filter.prefix}</InputGroup.Addon>
          ) : (
            <></>
          )}
          <Input
            type={filter.type === "NUMBER" ? "tel" : "text"}
            placeholder={filter.placeholder}
            minLength={filter.minLength}
            maxLength={filter.maxLength}
            defaultValue={(filterValues[filter.param] as string) || ""}
            // value={(filterValues[filter.param] as string) || ""}
            onChange={(val) => {
              debouncedFilterChange(val, filter.param);
            }}
          />
        </InputGroup>
      );
    }
    if (filter.type === "DROPDOWN") {
      return (
        <InputPicker
          data={filter.options.reduce((p: ItemDataType[], c) => {
            p.push({
              value: c.value,
              label: c.name,
            });
            return p;
          }, [])}
          value={filterValues[filter.param] || ""}
          onChange={(val) => {
            handleFilterChange(filter.param, val);
          }}
        />
      );
    }
    if (filter.type === "DATE-RANGE") {
      const [param1, param2] = filter.param.split(",");
      return (
          <CustomProvider theme={isDarkMode?"dark" : "light"}>

          <DateRangePicker
          placeholder="Select Date Range"
          format="dd/MM/yyyy"
          placement="bottomEnd"
          disabledDate={
            allowedRange && allowedRange(filter.endDate, new Date())
          }
          value={
            filterValues[param1] && filterValues[param2]
              ? [filterValues[param1] as Date, filterValues[param2] as Date]
              : null
          }
          onChange={(values) => {
            setFilterValues((prevState) => ({
              ...prevState,
              [param1]: values ? values[0] : "",
              [param2]: values ? values[1] : "",
            }));
          }}
        />
          </CustomProvider>
      );
    }
    return <></>;
  };

  return (
    <div className="header">
      <section className="headerLeft">
        <h1>{heading}</h1>
      </section>
      <section className="headerRight">
        {filtersList && filtersList.map(renderFilter)}
        <div className="headerProfile">
          <DropdownMenu image={selectedApp?.thumbnail} />
        </div>
      </section>
    </div>
  );
};
export default Header;
