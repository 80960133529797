import { CircularProgress } from "@mui/material";

const PageLoading = ({ message = "Loading..." }: { message?: string }) => {
  return (
    <div className="fill flex-center grow mb-20">
      <div>
        <CircularProgress />
        <p className="title">Loading...</p>
      </div>
    </div>
  );
};

export default PageLoading;
