import './style.scss'
import HeroPage from "./section/heroSection/HeroSection";
import FeaturesSection from "./section/featuresSection/FeaturesSection";
import SecurityFeaturesSection from "./section/securityFeaturesSection/SecurityFeaturesSection";
import UpperFooterSection from "./section/upperFooterSection/UpperFooterSection";
import LowerFooter from "./section/lowerFooterSection/LowerFooter";


export default function HomePage() {

    return(
        <div className='homePage'>
            <HeroPage/>
            <FeaturesSection/>
            <SecurityFeaturesSection/>
            <UpperFooterSection/>
            <LowerFooter/>
        </div>
    )
}