import { Download, Error, MoreVert, Refresh } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { HTMLAttributes, PropsWithChildren, useState } from "react";

type ChartCardProps = {
  isLoading?: boolean;
  isError?: boolean;
  noData?: boolean;
  onRetry?: () => void;
  showDownload?: boolean;
  onDownloadClick?: (type: "CSV" | "SVG") => void;
  title?: string;
} & PropsWithChildren &
  HTMLAttributes<HTMLDivElement>;

const ChartLoader = () => {
  return (
    <div className="flex-center fill">
      <CircularProgress />
    </div>
  );
};

const ChartCard = ({
  children,
  title,
  isLoading,
  className,
  isError,
  onRetry,
  noData,
  showDownload,
  onDownloadClick,
  ...otherProps
}: ChartCardProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={`chart-card ${className}`} {...otherProps}>
      {title && (
        <div className="head">
          <p className="title">{title}</p>
          <div className="actions">
            {showDownload && onDownloadClick ? (
              <div>
                <IconButton
                  aria-label="more"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <Download />
                </IconButton>
                <Menu
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      onDownloadClick("CSV");
                    }}
                  >
                    Save as CSV
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      onDownloadClick("SVG");
                    }}
                  >
                    Save as SVG
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      <div className="content">
        {isError ? (
          <div className="fill flex-center card-error">
            <div>
              <Error color="error" fontSize="large" />
              <p className="subtitle">Error fetching data</p>
              <Button variant="contained" size="small" onClick={onRetry}>
                Refresh <Refresh />
              </Button>
            </div>
          </div>
        ) : isLoading ? (
          <ChartLoader />
        ) : noData ? (
          <div className="fill flex-center card-error">
            <div>
              <Error color="warning" fontSize="large" />
              <p className="subtitle">Data not Available</p>
            </div>
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default ChartCard;
