import "./style.scss";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { createTheme, ThemeProvider } from "@mui/material";
import { RootState } from "../../store/store";
import SideMenu from "../../pages/dashboard_page/component/SideMenu/SideMenu";

export default function DashboardLayout() {
  const isDarkMode: boolean = useSelector(
    (state: RootState) => state.appReducer.isDarkMode
  );

  const darkTheme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <div
        className={`dashboard-layout-wrapper ${
          isDarkMode ? "dark-mode" : "light-mode"
        }`}
      >
        <div className={"dashboard-layout"}>
          <section className={"dashboard-layout-sidebar"}>
            <SideMenu />
          </section>
          <section className={"dashboard-layout-card"}>
            <Outlet />
          </section>
        </div>
      </div>
    </ThemeProvider>
  );
}
