import './style.scss'
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import {useEffect} from "react";
import {SourceTracking, Time, TotalVisit, Traffic, UniqueVisitor, UserRegestration} from "../icons";

type FeaturesProps = {
    heading: string,
    subHeading: string,
    img: "sourceTracking"|"time"|"totalVisit"|"traffic"|"uniqueVisitors"|"userRegistration",
}
export function Features({img, heading, subHeading}: FeaturesProps) {

    const animation = useAnimation();
    const [ref, inView] = useInView({
        threshold: 0.7
    });

    // useEffect(() => {
    //     if (inView) {
    //         animation.start({
    //             x: 0,
    //             y: 0,
    //             // rotate: 360,
    //             transition: {
    //                 duration: 1,
    //             }
    //         });
    //     } else {
    //         animation.start({
    //             x: 0,
    //             y: 0
    //         })
    //     }
    // }, [inView]);

    const renderIcon = () => {
        switch (img) {
            case "sourceTracking":
                return <SourceTracking animate={inView}/>
            case "time":
                return <Time animate={inView}/>

            case "totalVisit":
                return <TotalVisit animate={inView}/>

            case "traffic":
                return <Traffic animate={inView}/>

            case "uniqueVisitors":
                return <UniqueVisitor animate={inView}/>

            case "userRegistration":
                return <UserRegestration animate={inView}/>


            default:
                return null
        }
    }

    return (

        <section className='features-div' ref={ref}>
            <motion.div className='features-img' animate={animation}>
                {renderIcon()}
            </motion.div>
            <div className='features-heading'>
                <h1 className='heading'>{heading}</h1>
                <p>{subHeading}</p>
            </div>
        </section>

    )
}


