import {useCallback, useEffect, useMemo} from "react";
import ChartCard from "./ChartCard";
import {ChartComponentProps} from "./types";
import API from "../../../api";
import useApi from "../../../hooks/useApi";
import {CHART_TYPE, PieChartInfo} from "../../../interface/analytics";
import ReactECharts from "echarts-for-react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";

const PieChart = ({
                      chartDetails,
                      className,
                      filters,
                  }: ChartComponentProps) => {
    const apiCall = useCallback(
        () => API.getChartDetails<PieChartInfo>(chartDetails.key, filters),
        [chartDetails, filters]
    );
    const {isLoading, isError, response, fetchData} = useApi(apiCall);

    useEffect(() => {
        fetchData();
    }, [filters]);

    const data = useMemo(() => {
        let total = 0;
        if (response && response.chartData) {
            const data = response.chartData.map((val, index) => {
                total += val;
                return {
                    id: response.labels[index],
                    name: response.labels[index],
                    value: val,
                };
            });
            // sorting for consistency with order
            // even when date range is applied
            return [
                ...data.sort((a, b) => {
                    const nameA = (a?.name || "").toUpperCase(); // ignore upper and lowercase
                    const nameB = (b?.name || "").toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    // names must be equal
                    return 0;
                }),
                ...(chartDetails.type === CHART_TYPE.HALF_DONUT_CHART
                    ? [
                        {
                            value: total,
                            itemStyle: {
                                color: "none",
                                decal: {
                                    symbol: "none",
                                },
                            },
                            label: {
                                show: false,
                            },
                        },
                    ]
                    : []),
            ];
        }
        return [];
    }, [response]);

    const isDarkMode: boolean = useSelector(
        (state: RootState) => state.appReducer.isDarkMode
    );

    const colorChange = () => {
        if (!isDarkMode) {
            return "#132539";
        } else {
            return "#fff";
        }
    };

    const otherOptions = useMemo(() => {
        let out: any = {
            radius: "50%",
        };
        if (chartDetails.type === CHART_TYPE.HALF_DONUT_CHART) {
            out.radius = ["40%", "70%"];
            out.center = ["50%", "70%"];
            out.startAngle = 180;
            out.endAngle = 360;
        } else if (chartDetails.type === CHART_TYPE.DONUT_CHART) {
            out.radius = ["40%", "70%"];
            out.center = ["50%", "60%"];
        }
        return out;
    }, [chartDetails]);

    const getOption = () => {
        return {
            title: {
                text: chartDetails.title,
                left: "start",

                textStyle: {
                    color: colorChange(),
                    fontWeight: 700,
                    fontFamily: "Poppins",
                    fontSize: "19px",
                },
            },
            tooltip: {
                trigger: "item",
                formatter:
                    chartDetails.type === CHART_TYPE.HALF_DONUT_CHART
                        ? "{a} <br/>{b} : {c}"
                        : "{a} <br/>{b} : {c} ({d}%)",
                position: "bottom",
            },

            legend: {
                top: "8%",
                left: "center",
                selectedMode: false,
                textStyle: {
                    color: colorChange(),
                },
            },
            series: [
                {
                    name: chartDetails.title,
                    type: "pie",
                    endAngle: "auto",
                    ...otherOptions,
                    data: data,
                    label: {
                        show: false,
                    },
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                    },
                },
            ],
        };
    };

    return (
        <ChartCard
            // title={chartDetails.title}
            className={`${className} ${chartDetails.type}`}
            isLoading={isLoading}
            isError={isError}
            noData={data.length === 0}
            onRetry={fetchData}
        >
            <ReactECharts
                option={getOption()}
                // style={{ height: "20px", width: "100%" }}
            />
        </ChartCard>
    );
};

export default PieChart;
