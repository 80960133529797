import { useEffect } from "react";
import { CHART_TYPE, ChartInfoItem } from "../../../interface/analytics";
import BarChart from "./BarChart";
import BasicCount from "./BasicCount/BasicCount";
import ChartCard from "./ChartCard";
import HeatMapChart from "./HeatMapChart";
import HighlightedCount from "./HighlightedCount";
import HorizontalBarChart from "./HorizontalBarChart";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import CardWithHeader from "./CardWithHeader";
import HtmlChart from "./HtmlChart";

const ChartRenderer = ({
  chartInfo,
  filters,
}: {
  chartInfo: ChartInfoItem;
  filters: any;
}) => {
  if (chartInfo.type === CHART_TYPE.CONTAINER) {
    return (
      <div className={`container ${chartInfo.size}`}>
        {chartInfo.children?.map((item) => (
          <ChartRenderer chartInfo={item} key={item.key} filters={filters} />
        ))}
      </div>
    );
  }

  if (chartInfo.type === CHART_TYPE.HIGHLIGHTED_COUNT) {
    return (
      <HighlightedCount
        chartDetails={chartInfo}
        className={chartInfo.size}
        filters={filters}
      />
    );
  }
  if (chartInfo.type === CHART_TYPE.LINE_CHART) {
    return (
      <LineChart
        chartDetails={chartInfo}
        className={chartInfo.size}
        filters={filters}
      />
    );
  }
  if (chartInfo.type === CHART_TYPE.BAR_CHART) {
    return (
      <BarChart
        chartDetails={chartInfo}
        className={chartInfo.size}
        filters={filters}
      />
    );
  }
  if (chartInfo.type === CHART_TYPE.HORIZONTAL_BAR_CHART) {
    return (
      <HorizontalBarChart
        chartDetails={chartInfo}
        className={chartInfo.size}
        filters={filters}
      />
    );
  }
  if (chartInfo.type === CHART_TYPE.HEAT_MAP) {
    return (
      <HeatMapChart
        chartDetails={chartInfo}
        className={chartInfo.size}
        filters={filters}
      />
    );
  }
  if (chartInfo.type === CHART_TYPE.COUNT) {
    return (
      <BasicCount
        chartDetails={chartInfo}
        className={chartInfo.size}
        filters={filters}
      />
    );
  }
  if (
    [
      CHART_TYPE.PIE_CHART,
      CHART_TYPE.HALF_DONUT_CHART,
      CHART_TYPE.DONUT_CHART,
    ].includes(chartInfo.type)
  ) {
    return (
      <PieChart
        chartDetails={chartInfo}
        className={chartInfo.size}
        filters={filters}
      />
    );
  }
  if (chartInfo.type === CHART_TYPE.CARD_WITH_HEADER) {
    return (
      <CardWithHeader
        title={chartInfo.title}
        showDownload={chartInfo.canDownload}
        className={chartInfo.size}
      >
        {chartInfo.children?.map((item) => (
          <ChartRenderer chartInfo={item} key={item.key} filters={filters} />
        ))}
      </CardWithHeader>
    );
  }

  if (chartInfo.type === CHART_TYPE.HTML) {
    return (
      <HtmlChart
        chartDetails={chartInfo}
        className={chartInfo.size}
        filters={filters}
      />
    );
  }
  return null;
};

export default ChartRenderer;
