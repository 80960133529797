import { combineReducers } from "redux";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import appReducer from "./appReducer";

export default combineReducers({
  authReducer,
  userReducer,
  appReducer,
});
