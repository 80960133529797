import {IconProps} from "../index";
import './style.scss'

export function UserRegestration({animate}: IconProps) {

    return(
        <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">

            <g width="84" height="84" data-name="meassage">
                <g width="84" height="84" data-name="Group 48095575">
                    <g width="84" height="84" data-name="Group 48095473">
                        <rect width="84" height="84" rx="24" fill="url(#paint_linear___536)"
                              data-name="Rectangle 1109"/>
                    </g>
                    <g transform="translate(18 18)" clip-path="url(#clip_2_855)" width="48" height="48"
                       data-name="form 1">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M2 0H26C27.9884 0.00238179 29.8947 0.793323 31.3007 2.19933C32.7067 3.60534 33.4976 5.51161 33.5 7.5V40.5C33.5 40.8978 33.342 41.2794 33.0607 41.5607C32.7794 41.842 32.3978 42 32 42H8C6.01161 41.9976 4.10534 41.2067 2.69933 39.8007C1.29332 38.3947 0.502382 36.4884 0.5 34.5V1.5C0.5 1.10218 0.658036 0.720644 0.93934 0.43934C1.22064 0.158035 1.60218 0 2 0Z"
                              fill="white" data-name="Union" className={animate?"Union_172":""}/>
                        <rect width="25" height="4" rx="2" fill="#F6D780" data-name="Rectangle 1113"
                              className={animate?"Rectangle_1113_187":""}/>
                        <rect width="25" height="4" rx="2" fill="#F6D780" data-name="Rectangle 1114"
                              className={animate?"Rectangle_1114_56":""}/>
                        <rect width="25" height="4" rx="2" fill="#F6D780" data-name="Rectangle 1115"
                              className={animate?"Rectangle_1115_971":""}/>
                    </g>
                </g>
            </g>
            <defs>
                <linearGradient id="paint_linear___536" x1="42" y1="12" x2="42" y2="84" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EEB550"/>
                    <stop offset="1" stop-color="#FFF7AD"/>
                </linearGradient>
                <clipPath id="clip_2_855">
                    <rect width="48" height="48"/>
                </clipPath>
            </defs>
        </svg>
    )
}