import {IconProps} from "../index";
import './style.scss'

export function TotalVisit({animate}: IconProps) {

    return(
        <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">

            <g width="84" height="84" data-name="▶ Frame 3">
                <g width="84" height="84" data-name="Group 48095474">
                    <rect width="84" height="84" rx="24" fill="url(#paint_linear___90)" data-name="Rectangle 1108"/>
                    <path
                        d="M31.0074 0.0832325L1.00738 10.5832C0.723518 10.6824 0.476013 10.8646 0.296979 11.1062C0.117945 11.3478 0.0156466 11.6376 0.0033575 11.938C-0.00893158 12.2384 0.0693558 12.5356 0.228062 12.791C0.386768 13.0464 0.618566 13.2482 0.89338 13.3702L13.8654 19.1362L19.6314 32.1082C19.7492 32.3735 19.9415 32.5988 20.1848 32.757C20.4282 32.9151 20.7122 32.9993 21.0024 32.9992H21.0639C21.3634 32.986 21.6521 32.8834 21.8928 32.7045C22.1334 32.5257 22.3149 32.2788 22.4139 31.9957L32.9139 1.99573C33.0069 1.72965 33.023 1.44274 32.9605 1.1679C32.8979 0.893055 32.7592 0.641396 32.5602 0.44177C32.3612 0.242144 32.11 0.102629 31.8354 0.0392156C31.5607 -0.0241977 31.2738 -0.00894296 31.0074 0.0832325V0.0832325Z"
                        fill="white" data-name="Vector" className={animate?"Vector_729":""}/>
                    <g transform="translate(41.26 19.5)" className={animate?"Frame_3_305":""} width="23.23" height="23.23"
                       data-name="Frame 3">
                        <path
                            d="M1.73242 0C1.3346 0 0.953065 0.158035 0.671761 0.43934C0.390456 0.720644 0.232422 1.10218 0.232422 1.5V7.5C0.232422 7.89782 0.390456 8.27936 0.671761 8.56066C0.953065 8.84196 1.3346 9 1.73242 9C2.13025 9 2.51178 8.84196 2.79308 8.56066C3.07439 8.27936 3.23242 7.89782 3.23242 7.5V1.5C3.23242 1.10218 3.07439 0.720644 2.79308 0.43934C2.51178 0.158035 2.13025 0 1.73242 0Z"
                            fill="white" data-name="Vector" transform="translate(8.23 0)"/>
                        <path
                            d="M7.73242 0H1.73242C1.3346 0 0.953065 0.158036 0.671761 0.43934C0.390456 0.720645 0.232422 1.10218 0.232422 1.5C0.232422 1.89782 0.390456 2.27936 0.671761 2.56066C0.953065 2.84196 1.3346 3 1.73242 3H7.73242C8.13025 3 8.51178 2.84196 8.79308 2.56066C9.07439 2.27936 9.23242 1.89782 9.23242 1.5C9.23242 1.10218 9.07439 0.720645 8.79308 0.43934C8.51178 0.158036 8.13025 0 7.73242 0Z"
                            fill="white" data-name="Vector" transform="translate(14.23 12)"/>
                        <path
                            d="M3.29207 0.939622C3.00917 0.666385 2.63026 0.515194 2.23697 0.518611C1.84367 0.522029 1.46745 0.679782 1.18934 0.957894C0.911229 1.23601 0.753474 1.61223 0.750057 2.00552C0.746639 2.39882 0.897831 2.77772 1.17107 3.06062L4.92107 6.81062C5.20397 7.08386 5.58288 7.23505 5.97617 7.23163C6.36947 7.22822 6.74568 7.07046 7.0238 6.79235C7.30191 6.51424 7.45966 6.13802 7.46308 5.74472C7.4665 5.35143 7.31531 4.97253 7.04207 4.68962L3.29207 0.939622Z"
                            fill="white" data-name="Vector" transform="translate(12.75 16.52)"/>
                        <path
                            d="M4.17107 7.06062C4.45397 7.33386 4.83287 7.48505 5.22617 7.48163C5.61946 7.47822 5.99568 7.32046 6.27379 7.04235C6.55191 6.76424 6.70966 6.38802 6.71308 5.99472C6.7165 5.60143 6.56531 5.22253 6.29207 4.93962L2.54207 1.18962C2.25917 0.916385 1.88026 0.765194 1.48697 0.768611C1.09367 0.772029 0.717452 0.929782 0.43934 1.20789C0.161227 1.48601 0.00347433 1.86223 5.6704e-05 2.25552C-0.00336092 2.64882 0.147831 3.02772 0.421068 3.31062L4.17107 7.06062Z"
                            fill="white" data-name="Vector" transform="translate(0 3.77)"/>
                        <path
                            d="M2.2321 7.49988C2.62989 7.49979 3.01136 7.3417 3.2926 7.06038L7.0426 3.31038C7.18587 3.17201 7.30014 3.00649 7.37875 2.82348C7.45737 2.64048 7.49875 2.44365 7.50048 2.24448C7.50221 2.04531 7.46426 1.84779 7.38883 1.66345C7.31341 1.4791 7.20203 1.31162 7.06119 1.17079C6.92035 1.02995 6.75288 0.918566 6.56853 0.843145C6.38419 0.767724 6.18667 0.729771 5.9875 0.731502C5.78833 0.733233 5.5915 0.774612 5.4085 0.853226C5.22549 0.931839 5.05997 1.04611 4.9216 1.18938L1.1716 4.93938C0.961887 5.14916 0.819078 5.4164 0.761227 5.70733C0.703376 5.99826 0.733081 6.29981 0.846586 6.57386C0.960091 6.84791 1.1523 7.08215 1.39891 7.24698C1.64553 7.41181 1.93548 7.49981 2.2321 7.49988Z"
                            fill="white" data-name="Vector" transform="translate(12.73 3.73)"/>
                    </g>
                </g>
            </g>
            <defs>
                <linearGradient id="paint_linear___90" x1="42" y1="0" x2="42" y2="84" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#01B7CD"/>
                    <stop offset="1" stop-color="#9FF4DE"/>
                </linearGradient>

            </defs>
        </svg>
    )
}