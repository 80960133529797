import './style.scss'

type HorizontalCardProps = {
    style: any,
    heading: string,
    subHeading: string,
    img:string,
}
export function HorizontalCard({ style, heading,subHeading, img }: HorizontalCardProps) {

    return (
        <section className='big_card_section' style={style}>
            <div className="big_card_heading">
                <h1>{heading}</h1>
                <p>{subHeading}</p>
            </div>
            <div className="big_card_img">
                <img src={img}/>
            </div>
        </section>
    )

}