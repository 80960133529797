import './style.scss'

type UpperFooterComponentProps = {
    heading: string,
    subHeading: string,
    img:string,
    className: string
}
export function UpperFooterComponent({ heading,subHeading, img,className }: UpperFooterComponentProps) {

    return (
        <div className='main-div'>
            <div className='imag-div'>
                <img src={img} className={className}/>
            </div>
            <div className='heading-div'>
                <h1>{heading}</h1>
                <p>{subHeading}</p>
            </div>
        </div>
    )
}