import { AppListItem, ReportListItem } from "../../interface/api";
import {
  CLEAR_REPORT_LIST,
  CLEAR_APP_SELECTION,
  CLEAR_USER_DETAILS,
  SELECT_APP,
  SET_REPORT_LIST,
  SET_USER_DETAILS,
  SET_APPS_COUNT,
} from "../types";

export interface UserReducerStore {
  key: string;
  name: string;
  email: string;
  dp: string;
  role: string;
  appsCount: number;
  selectedApp: AppListItem | null;
  reportsList: ReportListItem[];
}

const initialState: UserReducerStore = {
  key: "",
  name: "",
  email: "",
  dp: "",
  role: "",
  appsCount: 0,
  selectedApp: null,
  reportsList: [],
};

type ActionType =
  | {
      type: typeof SET_USER_DETAILS;
      payload: UserReducerStore;
    }
  | {
      type: typeof SELECT_APP;
      payload: AppListItem;
    }
  | {
      type: typeof CLEAR_APP_SELECTION;
    }
  | {
      type: typeof SET_APPS_COUNT;
      payload: number;
    }
  | {
      type: typeof SET_REPORT_LIST;
      payload: ReportListItem[];
    }
  | {
      type: typeof CLEAR_REPORT_LIST;
    }
  | {
      type: typeof CLEAR_USER_DETAILS;
    };

export default function userReducer(
  state = initialState,
  action: ActionType
): UserReducerStore {
  switch (action.type) {
    case SET_USER_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case SELECT_APP:
      return {
        ...state,
        selectedApp: action.payload,
      };
    case CLEAR_APP_SELECTION:
      return {
        ...state,
        selectedApp: null,
      };
    case SET_APPS_COUNT:
      return {
        ...state,
        appsCount: action.payload,
      };
    case SET_REPORT_LIST:
      return {
        ...state,
        reportsList: action.payload,
      };
    case CLEAR_REPORT_LIST:
      return {
        ...state,
        reportsList: [],
      };
    case CLEAR_USER_DETAILS:
      return {
        key: "",
        name: "",
        email: "",
        dp: "",
        role: "",
        selectedApp: null,
        reportsList: [],
        appsCount: 0,
      };
    default:
      return state;
  }
}
