import './style.scss'

type VerticalCardProps = {
    style: any,
    heading: string,
    subHeading: string,
    img:string,
}


export function VerticalCard({ style, heading,subHeading, img }: VerticalCardProps) {

    return (
        <section className='small_card_section' style={style}>
            <div className="small_card_heading">
                <h1>{heading}</h1>
                <p>{subHeading}</p>
            </div>
            <div className="small_card_img">
                <img src={img}/>
            </div>
        </section>
    )
}


