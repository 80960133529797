import './style.scss'
import {UpperFooterComponent} from "../../components/upperFooterComponent/upperFooterComponent";

import browser from "../../../../assets/homepage/upperFooterSection/browser.png"
import traffic from "../../../../assets/homepage/upperFooterSection/traffic.png"
import dropOff from "../../../../assets/homepage/upperFooterSection/drop_off.png"
import plug from "../../../../assets/homepage/upperFooterSection/plug.png"

export default function UpperFooterSection() {

    return (
        <section className='upperFooter-section'>
            <div className='section-divs'>
                <UpperFooterComponent img={browser} className={"child"} heading={"Browser"}
                                      subHeading={"Tailor make products based on most used browser interaction."}/>
                <UpperFooterComponent img={dropOff} heading={"Drop-off Rate"} className=''
                                      subHeading={"Indicates how many potential users did not complete purchase"}/>
                <UpperFooterComponent img={traffic} className={"child"} heading={"Traffic Heat Map"}
                                      subHeading={"Show user behaviour on webpages during specific days & time."}/>
                <UpperFooterComponent img={plug} className={"child"} heading={"Plug & Play"} subHeading={"View current and future trends"}/>
            </div>
        </section>
    )
}