import "./SourceTracking.scss"
import {IconProps} from "../index";
const SourceTracking = ({animate}: IconProps) => {
    return (<svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g width="84" height="84" data-name="▶ Frame 4">
        <g width="84" height="84" data-name="Group 48095577">
            <g width="84" height="84" data-name="Group 48095475">
                <rect width="84" height="84" rx="24" fill="url(#paint_linear___269)" data-name="Rectangle 1108"/>
            </g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 0C21.6046 0 22.5 0.89544 22.5 2V4.12378C29.7374 5.02628 35.4738 10.7626 36.3762 18H38.5C39.6046 18 40.5 18.8954 40.5 20C40.5 21.1046 39.6046 22 38.5 22H36.3762C35.4738 29.2374 29.7374 34.9738 22.5 35.8762V38C22.5 39.1046 21.6046 40 20.5 40C19.3954 40 18.5 39.1046 18.5 38V35.8762C11.2626 34.9738 5.52628 29.2374 4.62378 22H2.5C1.39544 22 0.5 21.1046 0.5 20C0.5 18.8954 1.39544 18 2.5 18H4.62378C5.52628 10.7626 11.2626 5.02628 18.5 4.12378V2C18.5 0.89544 19.3954 0 20.5 0V0ZM18.5 8.16592C13.475 9.00896 9.50896 12.975 8.66592 18H10.5C11.6046 18 12.5 18.8954 12.5 20C12.5 21.1046 11.6046 22 10.5 22H8.66592C9.50896 27.025 13.475 30.991 18.5 31.834V30C18.5 28.8954 19.3954 28 20.5 28C21.6046 28 22.5 28.8954 22.5 30V31.834C27.525 30.991 31.491 27.025 32.334 22H30.5C29.3954 22 28.5 21.1046 28.5 20C28.5 18.8954 29.3954 18 30.5 18H32.334C31.491 12.975 27.525 9.00896 22.5 8.16592V10C22.5 11.1046 21.6046 12 20.5 12C19.3954 12 18.5 11.1046 18.5 10V8.16592ZM18.5 20C18.5 18.8954 19.3954 18 20.5 18H20.52C21.6246 18 22.52 18.8954 22.52 20C22.52 21.1046 21.6246 22 20.52 22H20.5C19.3954 22 18.5 21.1046 18.5 20Z" fill="white" data-name="Vector" className={animate?"Vector_164":""}/>
        </g>
    </g>
    <defs>
    <linearGradient id="paint_linear___269" x1="25.5" y1="84" x2="28" y2="1.27902e-06" gradientUnits="userSpaceOnUse">
    <stop stop-color="#AFDCFF"/>
    <stop offset="1" stop-color="#6594E2"/>
    </linearGradient>
    
    </defs>
    </svg>)
}

export default SourceTracking;