import { Download, Error, MoreVert, Refresh } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { HTMLAttributes, PropsWithChildren, useState } from "react";

type CardWithHeaderProps = {
  title: string;
  isLoading?: boolean;
  isError?: boolean;
  noData?: boolean;
  onRetry?: () => void;
  showDownload?: boolean;
  onDownloadClick?: (type: "CSV" | "SVG") => void;
} & PropsWithChildren &
  HTMLAttributes<HTMLDivElement>;

const ChartLoader = () => {
  return (
    <div className="flex-center fill">
      <CircularProgress />
    </div>
  );
};

const CardWithHeader = ({
  children,
  title,
  isLoading,
  className,
  isError,
  onRetry,
  noData,
  showDownload,
  onDownloadClick,
  ...otherProps
}: CardWithHeaderProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div
      className={`card-with-header ${className} ${
        title === "Other Insights" ? "restricted-height" : ""
      }`}
      {...otherProps}
    >
      <div className="card-with-header-head">
        <p className="title">{title}</p>
      </div>
      <div className="content">{children}</div>
    </div>
  );
};

export default CardWithHeader;
