import { BaseResponse } from "./api";

export enum CHART_TYPE {
  CONTAINER = "container",
  CARD = "card",
  CARD_WITH_HEADER = "cardWithHeader",
  LINE_CHART = "line-chart",
  HALF_DONUT_CHART = "half-donut-chart",
  PIE_CHART = "pie-chart",
  DONUT_CHART = "donut-chart",
  HORIZONTAL_BAR_CHART = "horizontal-bar-chart",
  BAR_CHART = "bar-chart",
  FUNNEL_CHART = "funnel-chart",
  HEAT_MAP = "heat-map",
  HTML = "html",
  COUNT = "insight-count",
  HIGHLIGHTED_COUNT = "i-count",
}

export enum CHART_SIZE {
  HALF = "half",
  FULL = "full",
  ONE_THIRD = "one-third",
  ONE_FOURTH = "one-fourth",
  TWO_THIRD = "two-third",
  THREE_FOURTH = "three-fourth",
}

export interface ChartInfoItem {
  key: string;
  title: string;
  type: CHART_TYPE;
  size: CHART_SIZE;
  children: ChartInfoItem[] | null;
  canDownload: boolean;
  color: "purple" | "blue" | "pink" | "orange";
  icon: string;
}

export type GetChartDetailsResponse<T> = T & BaseResponse;

export interface HighlightedCountChartInfo {
  chartType: CHART_TYPE.HIGHLIGHTED_COUNT;
  datasets: {
    icon: string;
    title: string;
    value: string;
  }[];
}
export interface BasicCountChartInfo {
  chartType: CHART_TYPE.COUNT;
  value: string;
}
export interface BarChartInfo {
  chartType: CHART_TYPE.BAR_CHART;
  chartData: number[];
  labels: string[];
  parameters: {
    title: string;
    labelString: string;
  };
}
export interface LineChartInfo {
  chartType: CHART_TYPE.LINE_CHART;
  labels: string[];
  chartData: {
    label: string;
    value: string[];
  }[];
  parameters: {
    title: string;
    xLabelString: string;
    yLabelString: string;
  };
}
export interface HeatMapChartInfo {
  chartType: CHART_TYPE.HEAT_MAP;
  chartData: {
    value: 0;
    bucketNo: 1;
  }[][];
  parameters: {
    title: string;
    legend: string[];
    xLabels: string[];
    yLabels: string[];
  };
}
export interface PieChartInfo {
  chartType: CHART_TYPE.PIE_CHART;
  labels: string[];
  chartData: number[];
  parameters: {
    title: string;
  };
}
export interface HtmlChartInfo {
  chartType: CHART_TYPE.PIE_CHART;
  title: string;
  html: string;
}
