import {IconProps} from "../index";
import './style.scss'
export function Time({animate}: IconProps) {

    return (
        <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">

            <g width="84" height="84" data-name="Frame 9">
                <g width="84" height="84" data-name="Group 48095578">
                    <g width="84" height="84" data-name="Group 48095477">
                        <rect width="84" height="84" rx="24" fill="url(#paint_linear___506)"
                              data-name="Rectangle 1108"/>
                    </g>
                    <g transform="translate(24 24)" clip-path="url(#clip_2_706)" width="36" height="36"
                       data-name="clock (1) 1">
                        <g transform="translate(1.5 1.5)" className={animate?"Layer_2_46":""} width="33" height="33"
                           data-name="Layer 2">
                            <path
                                d="M16.5 0C13.2366 0 10.0465 0.967708 7.3331 2.78075C4.61969 4.59379 2.50484 7.17074 1.25599
                                10.1857C0.00714925 13.2007 -0.319606 16.5183 0.31705 19.719C0.953706 22.9197 2.52518 25.8597
                                4.83274 28.1673C7.14031 30.4748 10.0803 32.0463 13.281 32.683C16.4817 33.3196 19.7993 32.9929
                                22.8143 31.744C25.8293 30.4952 28.4062 28.3803 30.2193 25.6669C32.0323 22.9535 33 19.7634 33
                                16.5C32.9948 12.1255 31.2548 7.93167 28.1616 4.83844C25.0683 1.74521 20.8745 0.00516071 16.5
                                 0V0ZM22.0605 22.0605C21.7792 22.3417 21.3977 22.4997 21 22.4997C20.6023 22.4997 20.2208 22.3417
                                  19.9395 22.0605L15.4395 17.5605C15.1582 17.2793 15.0001 16.8978 15 16.5V7.5C15 7.10217 15.158
                                  6.72064 15.4393 6.43934C15.7206 6.15803 16.1022 6 16.5 6C16.8978 6 17.2794 6.15803 17.5607
                                   6.43934C17.842 6.72064 18 7.10217 18 7.5V15.879L22.0605 19.9395C22.3417 20.2208 22.4997
                                    20.6023 22.4997 21C22.4997 21.3977 22.3417 21.7792 22.0605 22.0605V22.0605Z"
                                fill="white" data-name="Vector"/>
                        </g>
                    </g>
                </g>
            </g>
            <defs>
                <linearGradient id="paint_linear___506" x1="42" y1="0" x2="42" y2="84" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#ADD893"/>
                    <stop offset="1" stop-color="#D1EAC3"/>
                </linearGradient>
                <clipPath id="clip_2_706">
                    <rect width="36" height="36"/>
                </clipPath>
            </defs>
        </svg>
    )
}