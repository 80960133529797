import {IconProps} from "../index";
import './style.scss'

export function UniqueVisitor({animate}: IconProps) {

    return(
        <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">

            <g width="84" height="84" data-name="Frame 7">
                <g width="84" height="84" data-name="Group 48095572">
                    <g width="84" height="84" data-name="Group 48095472">
                        <rect width="84" height="84" rx="24" fill="url(#paint_linear___324)"
                              data-name="Rectangle 1109"/>
                    </g>
                    <g transform="translate(18 18)" clip-path="url(#clip_2_955)" width="48" height="48"
                       data-name="group 1">
                        <g transform="translate(12.83 3.64)" className={animate?"Group_440":""} width="22.34" height="40.72"
                           data-name="Group">
                            <g width="22.34" height="40.72" data-name="Group">
                                <path
                                    d="M9.17097 0C4.57609 0 0.837891 3.73819 0.837891 8.33317C0.837891 11.4499 2.55811 14.1719 5.09856 15.6011C6.30353 16.2789 7.69272 16.6663 9.17097 16.6663C10.6492 16.6663 12.0384 16.2789 13.2434 15.6011C15.7839 14.1719 17.504 11.4498 17.504 8.33317C17.504 3.73829 13.7659 0 9.17097 0Z"
                                    fill="white" data-name="Vector" transform="translate(2.84 0)"/>
                                <path
                                    d="M15.4155 0.373047H6.92908C3.10838 0.373047 0 3.48143 0 7.30212V21.3318C0 22.0971 0.620439 22.7177 1.38582 22.7177H20.9588C21.7242 22.7177 22.3446 22.0972 22.3446 21.3318V7.30212C22.3446 3.48143 19.2362 0.373047 15.4155 0.373047Z"
                                    fill="white" data-name="Vector" transform="translate(0 18.37)"/>
                            </g>
                        </g>
                        <g transform="translate(0 11.4)" className={animate?"Group_962":""} width="15.6" height="32.95"
                           data-name="Group">
                            <g transform="translate(3.14 0)" width="12.46" height="12.46" data-name="Group">
                                <path
                                    d="M6.23204 0C2.79563 0 0 2.79563 0 6.23204C0 9.66846 2.79563 12.4641 6.23204 12.4641C7.10373 12.4641 7.9338 12.2835 8.68783 11.959C9.99152 11.3977 11.0665 10.4041 11.7316 9.15958C12.1985 8.28611 12.4641 7.28964 12.4641 6.23204C12.4641 2.79572 9.66846 0 6.23204 0Z"
                                    fill="white" data-name="Vector"/>
                            </g>
                            <g transform="translate(0 14.22)" width="10.64" height="18.73" data-name="Group">
                                <g width="10.64" height="18.73" data-name="Group">
                                    <path
                                        d="M10.0561 3.31913C10.0561 2.15382 10.2628 1.03594 10.6412 0H6.92908C3.10838 0 0 3.10838 0 6.92907V14.5773C0 16.8697 1.86497 18.7348 4.15745 18.7348H10.2945C10.1407 18.3009 10.0561 17.8347 10.0561 17.3488V3.31913V3.31913Z"
                                        fill="white" data-name="Vector"/>
                                </g>
                            </g>
                        </g>
                        <g transform="translate(32.4 11.4)" className={animate?"Group_770":""} width="15.6" height="32.95"
                           data-name="Group">
                            <g width="12.46" height="12.46" data-name="Group">
                                <path
                                    d="M6.23204 0C2.79563 0 0 2.79563 0 6.23204C0 7.28973 0.265596 8.2862 0.732472 9.15958C1.39763 10.4042 2.47257 11.3978 3.77626 11.959C4.53029 12.2835 5.36035 12.4641 6.23204 12.4641C9.66846 12.4641 12.4641 9.66846 12.4641 6.23204C12.4641 2.79563 9.66846 0 6.23204 0Z"
                                    fill="white" data-name="Vector"/>
                            </g>
                            <g transform="translate(4.96 14.22)" width="10.64" height="18.73" data-name="Group">
                                <g width="10.64" height="18.73" data-name="Group">
                                    <path
                                        d="M3.71213 0H0C0.378376 1.03594 0.585095 2.15391 0.585095 3.31913V17.3488C0.585095 17.8347 0.500532 18.301 0.346688 18.7347H6.48367C8.77614 18.7347 10.6411 16.8696 10.6411 14.5772V6.92898C10.6412 3.10838 7.53283 0 3.71213 0Z"
                                        fill="white" data-name="Vector"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <defs>
                <linearGradient id="paint_linear___324" x1="44.5" y1="-13.5" x2="42" y2="84"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF507D"/>
                    <stop offset="1" stop-color="#FFEBD8"/>
                </linearGradient>
                <clipPath id="clip_2_955">
                    <rect width="48" height="48"/>
                </clipPath>
            </defs>
        </svg>    )
}