import { CLEAR_ACCESS_DETAILS, SET_ACCESS_TOKEN } from "../types";

type AuthReducerType = {
  accessToken: string;
};

const initialState: AuthReducerType = {
  accessToken: "",
};

type ActionType =
  | {
      type: typeof SET_ACCESS_TOKEN;
      payload: string;
    }
  | {
      type: typeof CLEAR_ACCESS_DETAILS;
    };
export default function authReducer(
  state = initialState,
  action: ActionType
): AuthReducerType {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case CLEAR_ACCESS_DETAILS:
      return {
        accessToken: "",
      };
    default:
      return state;
  }
}
