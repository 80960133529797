import "./style.scss";
import React from "react";

interface profileCarsProps {
  image: string;
  title: string;
  alt: string;
  onClick: () => void;
}

const ProfileCard: React.FC<profileCarsProps> = ({
  image,
  title,
  alt,
  onClick,
}) => {
  return (
    <div className="profile-card" onClick={onClick}>
      <img src={image} alt={alt} className="profile-img" />
      <h1 className="profile-title">{title}</h1>
    </div>
  );
};
export default ProfileCard;
