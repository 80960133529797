import { AppListItem } from "../../interface/api";
import { SET_APP_DETAILS, TOGGLE_DARK_MODE } from "../types";

interface AppState {
  isDarkMode: boolean;
  appKey: string;
  title: string;
  thumbnail: string;
}

const initialState: AppState = {
  isDarkMode: false,
  appKey: "",
  title: "",
  thumbnail: "",
};

type ActionType =
  | {
      type: typeof TOGGLE_DARK_MODE;
    }
  | {
      type: typeof SET_APP_DETAILS;
      payload: AppListItem;
    };

export default function appReducer(
  state = initialState,
  action: ActionType
): AppState {
  switch (action.type) {
    case TOGGLE_DARK_MODE:
      return {
        ...state,
        isDarkMode: !state.isDarkMode,
      };
    case SET_APP_DETAILS:
      return {
        ...state,
        appKey: action.payload.key,
        title: action.payload.title,
        thumbnail: action.payload.thumbnail,
      };
    default:
      return state;
  }
}
