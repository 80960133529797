import Lottie from "lottie-react";
import anim from './anim.json';

function LottieOne() {

    const style = {
        width: "30vw",

    };


    return (
        <div className="hero-lottie">
            <Lottie animationData={anim} style={style}/>

        </div>
    )
}

export default LottieOne;