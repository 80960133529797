import {IconProps} from "../index";
import './style.scss'

export function Traffic({animate}: IconProps) {

    return(
        <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">

            <g width="84" height="84" data-name="Frame 8">
                <g width="84" height="84" data-name="Group 48095579">
                    <g width="84" height="84" data-name="Group 48095476">
                        <rect width="84" height="84" rx="24" fill="url(#paint_linear___514)"
                              data-name="Rectangle 1108"/>
                    </g>
                    <g transform="translate(27 26)" clip-path="url(#clip_2_916)" width="36" height="36"
                       data-name="poll 1">
                        <g transform="translate(0 2.3)" width="36" height="31.4"
                           data-name="Layer_2_00000054239087896403216800000004248312561479916951_">
                            <g width="36" height="31.4" data-name="Social_Icon">
                                <g width="36" height="31.4" data-name="PollHorizontal">
                                    <path
                                        d="M24.0012 4.21875C24.0012 6.54609 22.1096 8.4375 19.782 8.4375H4.22033C1.89276 8.4375 0.00117159 6.54609 0.00117159 4.21875C0.00117159 1.89141 1.89276 0 4.22033 0H19.782C22.1166 0 24.0012 1.88438 24.0012 4.21875V4.21875Z"
                                        fill="white" data-name="Vector" className={animate?"Vector_347":""}/>
                                    <path
                                        d="M36.0012 4.69875C36.0012 7.02609 34.1098 8.9175 31.7824 8.9175H4.21992C1.89258 8.9175 0.00117159 7.02609 0.00117159 4.69875C0.00117159 2.37141 1.89258 0.48 4.21992 0.48H31.7824C34.1098 0.48 36.0012 2.37141 36.0012 4.69875Z"
                                        fill="white" data-name="Vector" className={animate?"Vector_936":""}/>
                                    <path
                                        d="M12.0023 5.17875C12.0023 7.50609 10.1109 9.3975 7.78359 9.3975H4.21875C1.89141 9.3975 0 7.50609 0 5.17875C0 2.85141 1.89141 0.959999 4.21875 0.959999H7.78359C10.1109 0.959999 12.0023 2.85141 12.0023 5.17875Z"
                                        fill="white" data-name="Vector" className={animate?"Vector_407":""}/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <defs>
                <linearGradient id="paint_linear___514" x1="42" y1="0" x2="42" y2="84" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#97E3ED"/>
                    <stop offset="1" stop-color="#D9D3F8"/>
                </linearGradient>
                <clipPath id="clip_2_916">
                    <rect width="36" height="36"/>
                </clipPath>
            </defs>
        </svg>
    )
}