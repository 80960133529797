import "./style.scss";
import main_logo from "../../../../assets/homepage/heroSection/logo.png";
import ProfileCard from "../../components/profileCard/ProfileCard";
import useApi from "../../../../hooks/useApi";
import API from "../../../../api";
import { Button, Skeleton } from "@mui/material";
import { Error } from "@mui/icons-material";
import { ROUTES } from "../../../../lib/consts";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setAppsCount,
  setSelectedApp,
} from "../../../../store/actions/userAction";
import { useEffect } from "react";

export default function AppSelectionPage() {
  const { isLoading, isError, response, fetchData } = useApi(API.getApps);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (response) {
      dispatch(setAppsCount(response.data.apps.length));
    }
  }, [response]);

  const renderContent = () => {
    if (isLoading) {
      return <Skeleton />;
    }
    if (isError) {
      return (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Error color="error" fontSize="large" />
          <p className="title">Error fetching data</p>
          <p className="subtitle">Please try again later</p>
          <Button variant="contained" className="retry" onClick={fetchData}>
            Retry
          </Button>
        </div>
      );
    }
    return (
      <>
        {response?.data.apps.map(({ title, key, thumbnail }) => (
          <ProfileCard
            image={thumbnail}
            title={title}
            alt={title}
            key={key}
            onClick={() => {
              dispatch(setSelectedApp({ key, title, thumbnail }));
              navigate(ROUTES.ANALYTICS(key));
            }}
          />
        ))}
      </>
    );
  };

  return (
    <div className="app-selection-page">
      <section className="hero_logo">
        <img src={main_logo} alt={"logo"} />
      </section>
      <section className="profileSection">
        <h1 className="profileSection-heading">Choose Brand</h1>
        <div className="profiles">{renderContent()}</div>
      </section>
    </div>
  );
}
