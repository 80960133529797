export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const CLEAR_ACCESS_DETAILS = "CLEAR_ACCESS_DETAILS";

export const TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE";
export const SET_APP_DETAILS = "SET_APP_DETAILS";

export const SELECT_APP = "SELECT_APP";
export const CLEAR_APP_SELECTION = "CLEAR_APP_SELECTION";

export const SET_REPORT_LIST = "SET_REPORT_LIST";
export const CLEAR_REPORT_LIST = "CLEAR_REPORT_LIST";

export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS";

export const SET_APPS_COUNT = "SET_APPS_COUNT";
