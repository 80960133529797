import { createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/home_page/HomePage";
import { ROUTES } from "../lib/consts";
import AppSelectionPage from "../pages/home_page/section/AppSelectionPage/AppSelectionPage";
import Analytics from "../pages/Analytics/Analytics";
import PrivateRoute from "../helpers/PrivateRoute";
import DashboardLayout from "../layouts/DashboardLayout/DashboardLayout";
import Report from "../pages/Report/Report";
import ReportWrapper from "../pages/Report/ReportWrapper";

const router = createBrowserRouter(
  [
    {
      path: ROUTES.HOME_PAGE,
      element: <HomePage />,
    },
    {
      path: ROUTES.APPS,
      element: (
        <PrivateRoute>
          <AppSelectionPage />
        </PrivateRoute>
      ),
    },
    {
      element: (
        <PrivateRoute>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        {
          index: true,
          path: ROUTES.ANALYTICS(),
          element: <Analytics />,
        },
        {
          path: ROUTES.REPORT(),
          element: <ReportWrapper />,
        },
      ],
    },
    {
      path: "*",
      element: <h1>Error</h1>,
    },
  ],
  { basename: process.env.REACT_APP_BASE_URL }
);

export default router;
