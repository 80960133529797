import { useCallback, useEffect, useMemo, useState } from "react";
import "./Analytics.scss";
import API from "../../api";
import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { Error } from "@mui/icons-material";
import ChartRenderer from "./components/ChartRenderer";
import Header from "../dashboard_page/component/header/Header";
import store from "../../store/store";
import { setReports } from "../../store/actions/userAction";
import PageLoading from "../../components/PageLoading";
import PageError from "../../components/PageError";
import { getFormattedDate } from "../../helpers/helper";

const Analytics = () => {
  const { appKey } = useParams();
  const apiCall = useCallback(() => API.getAppDetails(appKey || ""), [appKey]);
  const { isLoading, isError, response, fetchData } = useApi(apiCall);

  const [filterPayload, setFilterPayload] = useState<any>({});

  useEffect(() => {
    if (response) {
      store.dispatch(setReports(response?.data.reports || []));
    }
  }, [response]);

  const renderContent = () => {
    if (isError) {
      return <PageError onRetry={fetchData} />;
    }

    if (isLoading) {
      return <PageLoading />;
    }

    if (response && response?.data.charts.length === 0) {
      return (
        <div className="fill flex-center grow mb-20">
          <div>
            <Error color="primary" fontSize="large" />
            <p className="title">Data Not Available</p>
          </div>
        </div>
      );
    }
    return (
      <div className="chart-layout">
        {response?.data.charts.map((item) => (
          <ChartRenderer
            chartInfo={item}
            key={item.key}
            filters={filterPayload}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="analytics-page">
      <Header
        heading={"Dashboard"}
        filtersList={response?.data.filters}
        onFilterChange={setFilterPayload}
      />
      {renderContent()}
    </div>
  );
};

export default Analytics;
